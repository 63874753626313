import _useCreateReport_ReportingPlatformLib_Mutation from "./__generated__/useCreateReport_ReportingPlatformLib_Mutation.graphql";
import _useCreateReport_ReportingPlatformLib_report from "./__generated__/useCreateReport_ReportingPlatformLib_report.graphql";
import { useMutation } from 'react-relay';
import { graphql } from '@attentive/data';
import Mutation from './__generated__/useCreateReport_ReportingPlatformLib_Mutation.graphql';
export { Mutation as CreateReportMutation };
_useCreateReport_ReportingPlatformLib_report;
_useCreateReport_ReportingPlatformLib_Mutation;
export const useCreateReport = () => {
  const [commit, isInFlight] = useMutation(Mutation);
  return [async _ref => {
    let {
      variables,
      onCompleted,
      onError
    } = _ref;
    commit({
      variables,
      onCompleted: res => onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted(res),
      onError: err => onError === null || onError === void 0 ? void 0 : onError(err)
    });
  }, isInFlight];
};