/**
 * @generated SignedSource<<2d643d4f01d85102f72590301d419750>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ReportChart = "REPORT_CHART_EXPANSION_REVENUE" | "REPORT_CHART_INCREMENTAL_REVENUE" | "REPORT_CHART_LINE_CHART" | "REPORT_CHART_SUBSCRIBER_GROWTH" | "REPORT_CHART_SUBSCRIBER_LIFT" | "REPORT_CHART_SUPPRESSED_SENDS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useUpdateReport_ReportingPlatformLib_report$data = {
  readonly selectedChart: ReportChart | null;
  readonly selectedMetricId: string | null;
  readonly sftpEnabled: boolean | null;
  readonly " $fragmentType": "useUpdateReport_ReportingPlatformLib_report";
};
export type useUpdateReport_ReportingPlatformLib_report$key = {
  readonly " $data"?: useUpdateReport_ReportingPlatformLib_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"useUpdateReport_ReportingPlatformLib_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useUpdateReport_ReportingPlatformLib_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sftpEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedChart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedMetricId",
      "storageKey": null
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "bbf96209795b07f8a53ab313155ca912";

export default node;
