/**
 * @generated SignedSource<<eafe4fd7da281740f276f2aee1bb9728>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NewRevenueModelStatus = "NEW_REVENUE_MODEL_STATUS_CANDIDATE" | "NEW_REVENUE_MODEL_STATUS_DISABLED" | "NEW_REVENUE_MODEL_STATUS_ENABLED" | "NEW_REVENUE_MODEL_STATUS_UNKNOWN" | "NEW_REVENUE_MODEL_STATUS_UNSUPPORTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useShopifyAttributionOptInStates_company$data = {
  readonly attributionSettings: {
    readonly newRevenueModel: {
      readonly optInTimestamp: SerializedDateTime;
      readonly status: NewRevenueModelStatus;
    };
  } | null;
  readonly externalId: number;
  readonly " $fragmentType": "useShopifyAttributionOptInStates_company";
};
export type useShopifyAttributionOptInStates_company$key = {
  readonly " $data"?: useShopifyAttributionOptInStates_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useShopifyAttributionOptInStates_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useShopifyAttributionOptInStates_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AttributionSettings",
      "kind": "LinkedField",
      "name": "attributionSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NewRevenueModel",
          "kind": "LinkedField",
          "name": "newRevenueModel",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "optInTimestamp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "9ba35e1c547ef46b3a00bc02ea5c1f91";

export default node;
