import _useUpdateReport_ReportingPlatformLib_report from "./__generated__/useUpdateReport_ReportingPlatformLib_report.graphql";
import _useUpdateReport_ReportingPlatformLib_Mutation from "./__generated__/useUpdateReport_ReportingPlatformLib_Mutation.graphql";
import { useFragment, useMutation } from 'react-relay';
import { graphql } from '@attentive/data';
import Mutation from './__generated__/useUpdateReport_ReportingPlatformLib_Mutation.graphql';
import ReportFragment from './__generated__/useUpdateReport_ReportingPlatformLib_report.graphql';
export { Mutation as UpdateReportMutation };
_useUpdateReport_ReportingPlatformLib_Mutation;
_useUpdateReport_ReportingPlatformLib_report;
export const useUpdateReport = reportRef => {
  const [commit, isInFlight] = useMutation(Mutation); // These fields must always be included in update mutations — if omitted, the backend overwrites them with null.
  // This fragment ensures they're preserved during unrelated actions (e.g., renaming) to avoid accidental resets.

  const reportInputDefaults = useFragment(ReportFragment, reportRef);
  return [async _ref => {
    let {
      variables,
      onCompleted,
      onError
    } = _ref;
    commit({
      variables: { ...reportInputDefaults,
        ...variables
      },
      onCompleted: res => onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted(res),
      onError: err => onError === null || onError === void 0 ? void 0 : onError(err)
    });
  }, isInFlight];
};